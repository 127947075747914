import { Box } from "@mui/material";
import React, { Suspense } from "react";
import { BrowserRouter as Router, useLocation } from "react-router-dom";

import { Spinner } from "src/shared";
import EmptyNavbar from "src/shared/navbar/EmptyNavbar";

import Routing from "./Routing";

const RootSuspense = () => {
  const location = useLocation();
  const showNavbar =
    !location.pathname.startsWith("/contact") &&
    !location.pathname.startsWith("/login");

  return (
    <div className="CenteredSpinner">
      <Box>{showNavbar && <EmptyNavbar />}</Box>
      <Spinner />
    </div>
  );
};

function MainRoutes() {
  return (
    <Router>
      <Suspense fallback={<RootSuspense />}>
        <Routing />
      </Suspense>
    </Router>
  );
}

export default MainRoutes;
