import { Configuration, PublicClientApplication } from "@azure/msal-browser";

const configuration: Configuration = {
  auth: {
    clientId: process.env.VITE_APP_SHAREPOINT_CLIENT_ID || "",
    authority: "https://login.microsoftonline.com/organizations/v2.0",
    redirectUri: "/settings/sharepoint_auth",
    postLogoutRedirectUri: "/",
  },
};

export const publicClientApplication = new PublicClientApplication(
  configuration,
);

export const sharepointScopes = ["Sites.ReadWrite.All"];
